import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>HIGH CLIMBER SERVICES</h1>
      <h3>Website Under Construction</h3>
      <h3>For Information, Call (305) 688-9881</h3>
      <div className="info-section">
      High Climber Services began in in 1979 and has been servicing South Florida more than 40 years. 
      The company specializes in one and two point suspended scaffold. 
      As a family held company, High Climber Services offers a personalized commitment to your specific swing stage needs. 
      We are here to help you with your project, large or small.
      </div>
    </div>
  );
}

export default App;
